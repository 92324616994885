/*
 * @Author: Axs <i@axs.email>
 * @Date: 2020-07-29 21:11:05
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2022-05-26 16:07:12
 * @Description: 支付统一方法
 * @FilePath: /src/lib/pay.js
 */
import store from '@/store';
import util from 'util';
import { QZ_PAY_TYPE } from 'constant';
import { WX_PAY_JSAPI } from 'api';
import { PAGE_ORDER_INFO, PAGE_WECHAT_PAY, PAGE_ORDER_PAY } from 'page';
import payType from '@/components/payType';

function pay(data) {
    return new Promise((resolve, reject) => {
        let dataList = [];
        if (!data.returnUrl) {
            data.returnUrl = `${window.location.origin}${PAGE_ORDER_INFO}?platformId=${store.state.common.platformId}&orderNo=${data.orderNo}&token=${store.state.user.token}`;
        }
        if (!data.source) {
            data.source = process.env.VUE_APP_IDENT_NAME;
        }
        for (let k in data) {
            dataList.push(`${k}=${encodeURIComponent(decodeURIComponent(data[k]))}`);
        }
        if (data.orderStatus && data.orderStatus == 5) {
            util.pageJump(PAGE_ORDER_INFO, {
                orderNo: data.orderNo,
            });
            return resolve({
                success: true,
                source: 'BackstagePayment',
            });
        }
        // 微信小程序支付
        if (store.getters.isWechatMin) {
            let url = `/pages/qianZhuPay/qianZhuPay?source=${data.source}&orderNo=${
                data.orderNo
            }&orderParam=${data.orderType}&token=${store.state.user.token}&appId=${
                store.state.common.config.wxMiniappPayAppId
            }&orderInfoUrl=${encodeURIComponent(decodeURIComponent(data.returnUrl))}`;
            // 跳转页面
            if (store.getters.getExposeMethod.toOrderPay) {
                util.pageJump(PAGE_ORDER_PAY, {
                    orderNo: data.orderNo,
                    orderType: data.orderType,
                });
            }
            if (store.state.common.platformId == 88) {
                url += `&orderId=${data.orderId}&orderType=${data.orderType}`;
                wx.miniProgram.navigateTo({
                    url: url,
                });
                setTimeout(() => {
                    util.pageBack();
                }, 100);
            } else {
                if (store.getters.getExposeMethod.payJumpType == 'navigateTo') {
                    wx.miniProgram.navigateTo({
                        url: url,
                    });
                } else {
                    wx.miniProgram.reLaunch({
                        url: url,
                    });
                }
                if (store.getters.getExposeMethod.createOrderBack) {
                    setTimeout(() => {
                        util.pageBack();
                    }, 100);
                }
            }
            resolve({
                success: true,
                source: 'WX_XCX',
                payType: QZ_PAY_TYPE.WXPAY_MIN,
            });
        }
        // 头条小程序支付
        else if (store.getters.isTTMin) {
            let url = `/pages/qianZhuPay/qianZhuPay?source=${data.source}&orderNo=${
                data.orderNo
            }&orderParam=${data.orderType}&token=${
                store.state.user.token
            }&orderInfoUrl=${encodeURIComponent(decodeURIComponent(data.returnUrl))}`;
            // 跳转页面
            if (store.state.common.platformId == 88) {
                url += `&orderId=${data.orderId}&orderType=${data.orderType}`;
                tt.miniProgram.navigateTo({
                    url: url,
                });
                setTimeout(() => {
                    util.pageBack();
                }, 100);
            } else {
                if (store.getters.getExposeMethod.payJumpType == 'navigateTo') {
                    tt.miniProgram.navigateTo({
                        url: url,
                    });
                } else {
                    tt.miniProgram.reLaunch({
                        url: url,
                    });
                }
                if (store.getters.getExposeMethod.createOrderBack) {
                    setTimeout(() => {
                        util.pageBack();
                    }, 100);
                }
            }
            resolve({
                success: true,
                source: 'TT_XCX',
                payType: QZ_PAY_TYPE.TTPAY_MIN,
            });
        }
        // 支付宝小程序支付
        else if (store.getters.isAlipayMin) {
            let url = `/pages/qianZhuPay/qianZhuPay?source=${data.source}&orderNo=${
                data.orderNo
            }&orderParam=${data.orderType}&token=${
                store.state.user.token
            }&orderInfoUrl=${encodeURIComponent(decodeURIComponent(data.returnUrl))}`;

            if (store.getters.getExposeMethod.payJumpType == 'navigateTo') {
                my.navigateTo({
                    url: url,
                });
            } else {
                my.reLaunch({
                    url: url,
                });
            }
            if (store.getters.getExposeMethod.createOrderBack) {
                setTimeout(() => {
                    util.pageBack();
                }, 100);
            }
            resolve({
                success: true,
                source: 'ALIPAY_XCX',
                payType: QZ_PAY_TYPE.ALIPAY_MIN,
            });
        }
        // 百度小程序支付
        else if (store.getters.isBDMin) {
            let url = `/pages/qianZhuPay/qianZhuPay?source=${
                process.env.VUE_APP_IDENT_NAME
            }&orderNo=${data.orderNo}&orderParam=${data.orderType}&token=${
                store.state.user.token
            }&orderInfoUrl=${encodeURIComponent(decodeURIComponent(data.returnUrl))}`;
            // 跳转页面
            if (store.state.common.platformId == 88) {
                url += `&orderId=${data.orderId}&orderType=${data.orderType}`;
                swan.webView.navigateTo({
                    url: url,
                });
                setTimeout(() => {
                    util.pageBack();
                }, 100);
            } else {
                if (store.getters.getExposeMethod.payJumpType == 'navigateTo') {
                    swan.webView.navigateTo({
                        url: url,
                    });
                } else {
                    swan.webView.reLaunch({
                        url: url,
                    });
                }
                if (store.getters.getExposeMethod.createOrderBack) {
                    setTimeout(() => {
                        util.pageBack();
                    }, 100);
                }
            }
            resolve({
                success: true,
                source: 'BD_XCX',
                payType: QZ_PAY_TYPE.BDPAY_MIN,
            });
        }
        // 肯德基自有支付
        else if (store.getters.getExposeMethod.kfcPayUrl) {
            let resultUrl = store.getters.getExposeMethod.kfcPayUrl;
            let isHasQuestionMark = resultUrl.indexOf('?') > -1;
            let expandText = `token=${store.state.user.token}&source=${data.source}&orderNo=${
                data.orderNo
            }&orderType=${data.orderType}&orderInfoUrl=${encodeURIComponent(
                decodeURIComponent(data.returnUrl)
            )}`;
            resultUrl += (isHasQuestionMark ? '&' : '?') + expandText;
            if (process.env.VUE_APP_MY_NODE == 'LOCAL') {
                console.log('唤醒第三方支付', resultUrl);
            } else {
                window.location.replace(resultUrl);
            }
            return resolve({
                success: true,
                source: 'other',
                payType: QZ_PAY_TYPE.OTHERPAY,
            });
        }
        // 渠道自有支付
        else if (store.getters.getExposeMethod.payUrl) {
            let resultUrl = store.getters.getExposeMethod.payUrl;
            let isHasQuestionMark = resultUrl.indexOf('?') > -1;
            let expandText = `token=${store.state.user.token}&source=${data.source}&orderNo=${
                data.orderNo
            }&orderType=${data.orderType}&orderInfoUrl=${encodeURIComponent(
                decodeURIComponent(data.returnUrl)
            )}`;
            resultUrl += (isHasQuestionMark ? '&' : '?') + expandText;
            if (process.env.VUE_APP_MY_NODE == 'LOCAL') {
                console.log('唤醒第三方支付', resultUrl);
            } else {
                window.location.replace(resultUrl);
            }
            return resolve({
                success: true,
                source: 'other',
                payType: QZ_PAY_TYPE.OTHERPAY,
            });
        }
        // 默认千猪支付
        else {
            let isTest = process.env.VUE_APP_MY_NODE == 'PRO' ? false : true;
            let resultUrl = `//m${isTest ? '-test' : ''}.qianzhu8.com/pay`;
            let isHasQuestionMark = resultUrl.indexOf('?') > -1;
            let expandText = `token=${store.state.user.token}&source=${data.source}&orderNo=${
                data.orderNo
            }&orderType=${data.orderType}&orderInfoUrl=${encodeURIComponent(
                decodeURIComponent(data.returnUrl)
            )}`;
            resultUrl += (isHasQuestionMark ? '&' : '?') + expandText;
            if (process.env.VUE_APP_MY_NODE == 'LOCAL') {
                console.log('唤醒默认千猪支付', resultUrl);
            } else {
                window.location.replace(resultUrl);
            }
            return resolve({
                success: true,
                source: 'other',
                payType: QZ_PAY_TYPE.OTHERPAY,
            });
        }
        // 支付宝支付
        // else if (store.getters.isAlipayBrowser) {
        //     util.get(`api/pay/alipay/createWebPayTrade2?appId=10008&${dataList.join('&')}`).then(
        //         alipayRes => {
        //             let tempResult = {
        //                 success: alipayRes.success,
        //                 data: alipayRes,
        //                 source: 'ZFB',
        //                 payType: QZ_PAY_TYPE.ALIPAY_H5,
        //             };
        //             if (alipayRes.success) {
        //                 payType.close();
        //                 var textDiv = document.createElement('div');
        //                 var time = new Date().getTime();
        //                 textDiv.className = 'qz-alipay';
        //                 textDiv.id = 'qz-alipay-' + time;
        //                 textDiv.innerHTML = alipayRes.data;
        //                 document.body.appendChild(textDiv);
        //                 document.forms[0].submit();
        //             }
        //             return resolve(tempResult);
        //         }
        //     );
        // }
        // // app && 其他浏览器 支付
        // else if (store.getters.isApp) {
        //     payType.open({
        //         payType: data.payType || null,
        //         success: payRes => {
        //             // 选择支付宝支付
        //             if (payRes.data.payType == QZ_PAY_TYPE.ALIPAY_H5) {
        //                 util.get(
        //                     `api/pay/alipay/createWebPayTrade2?appId=10008&${dataList.join('&')}`
        //                 ).then(alipayRes => {
        //                     let tempResult = {
        //                         success: alipayRes.success,
        //                         data: alipayRes,
        //                         source: 'ZFB',
        //                         payType: QZ_PAY_TYPE.ALIPAY_H5,
        //                     };
        //                     if (alipayRes.success) {
        //                         payType.close();
        //                         var textDiv = document.createElement('div');
        //                         var time = new Date().getTime();
        //                         textDiv.className = 'qz-alipay';
        //                         textDiv.id = 'qz-alipay-' + time;
        //                         textDiv.innerHTML = alipayRes.data;
        //                         document.body.appendChild(textDiv);
        //                         document.forms[0].submit();
        //                     }
        //                     return resolve(tempResult);
        //                 });
        //             }
        //             // 选择微信支付
        //             else if (payRes.data.payType == QZ_PAY_TYPE.WXPAY_H5) {
        //                 store.dispatch('wechatPay').then(wechatPayRes => {
        //                     const writeDomains = wechatPayRes.allowDomains;
        //                     const redirectDomain = wechatPayRes.redirectDomain;
        //                     if (writeDomains.indexOf(window.location.hostname) == -1) {
        //                         window.location.replace(
        //                             `https://${redirectDomain}${PAGE_WECHAT_PAY}?platformId=${
        //                                 store.state.common.platformId
        //                             }&token=${store.state.user.token}&${dataList.join('&')}`
        //                         );
        //                         return resolve({
        //                             success: true,
        //                             source: 'WX_OTHER',
        //                             payType: QZ_PAY_TYPE.WXPAY_H5,
        //                         });
        //                     } else {
        //                         data.returnUrl += `&backIndex=true`;
        //                         util.post('/api/pay/weChat/h5', {
        //                             orderId: data.orderId,
        //                             orderType: data.orderType,
        //                         }).then(wxRes => {
        //                             let tempResult = {
        //                                 success: wxRes.success,
        //                                 data: wxRes,
        //                                 source: 'WX',
        //                                 payType: QZ_PAY_TYPE.WXPAY_H5,
        //                             };
        //                             if (wxRes.success) {
        //                                 window.location.href = wxRes.data.mwebUrl;
        //                             }
        //                             return resolve(tempResult);
        //                         });
        //                     }
        //                 });
        //             }
        //         },
        //     });
        // }
        // // 微信浏览器支付
        // else if (store.getters.isWechatBrowser) {
        //     store.dispatch('wechatPay').then(wechatPayRes => {
        //         const writeDomains = wechatPayRes.allowDomains;
        //         const redirectDomain = wechatPayRes.redirectDomain;
        //         if (writeDomains.indexOf(window.location.hostname) == -1) {
        //             window.location.replace(
        //                 `https://${redirectDomain}${PAGE_WECHAT_PAY}?platformId=${
        //                     store.state.common.platformId
        //                 }&token=${store.state.user.token}&${dataList.join('&')}`
        //             );
        //             return resolve({
        //                 success: true,
        //                 source: 'WX_OTHER',
        //                 payType: QZ_PAY_TYPE.WXPAY_BROWSER,
        //             });
        //         } else {
        //             // 唤醒微信浏览器支付
        //             util.post(WX_PAY_JSAPI, data).then(payJSApiRes => {
        //                 if (payJSApiRes.success) {
        //                     let { timeStamp, nonceStr, packageValue, paySign, signType, appId } =
        //                         payJSApiRes.data;
        //                     wx.chooseWXPay({
        //                         timestamp: Number(timeStamp),
        //                         nonceStr,
        //                         package: packageValue,
        //                         signType,
        //                         paySign,
        //                         complete(res) {
        //                             let tempResult = {
        //                                 ...res,
        //                                 source: 'WX',
        //                                 success: res.errMsg.indexOf('ok') > -1,
        //                                 payType: QZ_PAY_TYPE.WXPAY_BROWSER,
        //                             };
        //                             return resolve(tempResult);
        //                         },
        //                     });
        //                 }
        //             });
        //         }
        //     });
        // }
    });
}
export default pay;
