<!--
 * @Author: Axs <i@axs.email>
 * @Date: 2020-12-02 11:00:55
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2022-07-19 15:20:25
 * @Description: 
 * @FilePath: /src/views/kfc/order/info.vue
-->
<template>
    <div
        class="page order-info"
        :class="[
            'order-' + orderStatus,
            { takeout: isTakeout },
            { delivery: orderDetail.delivery },
            'delivery-' + orderDetail.deliveryStatus,
            { 'complete-delivery': orderDetail.deliveryStatus == 50 },
        ]"
    >
        <template v-if="isTakeout">
            <div class="order-header" v-if="orderStatus == -5">
                <div class="status-icon"></div>
                <div class="status-text">订单已取消</div>
                <div class="status-tip">
                    {{
                        orderDetail.remark || orderDetail.kfRemark || orderDetail.orderPersonRemark
                    }}
                </div>
                <div
                    class="btn"
                    v-if="!$store.getters.getExposeMethod.kfcHideNextPay"
                    @click="jixuDian"
                >
                    再次购买
                </div>
            </div>
            <div class="order-header" v-if="orderStatus == 0">
                <div class="down-time">{{ time }}</div>
                <div class="status-text">待支付</div>
                <div class="status-tip">若超时未支付，订单将自动取消</div>
                <div class="btn" @click="payOrder">立即支付</div>
            </div>
            <div class="order-header" v-else-if="orderStatus == 5">
                <div class="status-icon"></div>
                <div class="status-text">餐点准备中</div>
                <div class="status-tip bold">
                    预计
                    <span class="red">{{ orderDetail.takeoutShortTime }}</span>
                    送达
                </div>
                <div
                    class="btn"
                    v-if="!$store.getters.getExposeMethod.kfcHideNextPay"
                    @click="jixuDian"
                >
                    再次购买
                </div>
            </div>
            <div class="order-header" v-else-if="orderStatus == 10">
                <div class="status-icon"></div>
                <div class="status-text">订单配送中</div>
                <div class="status-tip bold">
                    预计
                    <span class="red">{{ orderDetail.takeoutShortTime }}</span>
                    送达
                </div>
                <div
                    class="btn"
                    v-if="!$store.getters.getExposeMethod.kfcHideNextPay"
                    @click="jixuDian"
                >
                    再次购买
                </div>
            </div>
            <div class="order-header" v-else-if="orderStatus == 15">
                <div class="status-icon"></div>
                <div class="status-text">订单已完成</div>
                <div class="status-tip">订单已按时送达</div>
                <div
                    class="btn"
                    v-if="!$store.getters.getExposeMethod.kfcHideNextPay"
                    @click="jixuDian"
                >
                    再次购买
                </div>
            </div>
        </template>
        <template v-else>
            <div class="order-header" v-if="orderStatus == -5">
                <div class="status-icon"></div>
                <div class="status-text">已取消</div>
                <div class="status-tip"></div>
                <div
                    class="btn"
                    v-if="!$store.getters.getExposeMethod.kfcHideNextPay"
                    @click="jixuDian"
                >
                    再次购买
                </div>
            </div>
            <div class="order-header" v-if="orderStatus == 0">
                <div class="down-time">{{ time }}</div>
                <div class="status-text">待支付</div>
                <div class="status-tip">若超时未支付，订单将自动取消</div>
                <div class="btn" @click="payOrder">立即支付</div>
            </div>
            <div
                class="order-header wait-code"
                v-else-if="
                    orderStatus == 5 ||
                    (orderDetail.delivery &&
                        orderDetail.deliveryStatus == 50 &&
                        orderStatus == 15) ||
                    (!orderDetail.delivery && orderStatus == 15)
                "
            >
                <div
                    class="title"
                    v-if="
                        orderDetail.delivery &&
                        orderDetail.deliveryStatus == 50 &&
                        orderStatus == 15
                    "
                >
                    已配单完成
                </div>
                <div class="title" v-else>已完成下单，制作中</div>
                <div class="product-list" :class="{ 'hide-radius': orderDetail.content }">
                    <template v-if="PickUpMealsCodeList && PickUpMealsCodeList.length">
                        <template v-for="(productList, productListIndex) in PickUpMealsCodeList">
                            <template v-if="productList.structureType == 0">
                                <div
                                    class="product-item"
                                    :class="[
                                        { cancel: productList.canceled },
                                        { draw: !productList.canceled && !productList.ticket },
                                    ]"
                                    :key="productListIndex"
                                >
                                    <img class="product-image" :src="productList.imageUrl" alt="" />
                                    <div class="product-name">
                                        {{ productList.productName }}
                                    </div>
                                    <div class="quantity">x{{ productList.quantity }}</div>

                                    <template v-if="productList.ticket">
                                        <div class="subheading">取餐码</div>
                                        <div class="ticket-desc">
                                            <p v-for="(v, i) in productList.ticketList" :key="i">
                                                {{ v }}
                                            </p>
                                        </div>
                                        <div class="place-order-mobile">
                                            下单手机号：{{ productList.placeOrderMobile }}
                                        </div>
                                    </template>
                                    <template v-else-if="!productList.canceled">
                                        <div class="wait-text">
                                            出单中
                                            <span class="wait-spot">
                                                <span>...</span>
                                            </span>
                                        </div>
                                    </template>
                                    <template v-else-if="productList.canceled">
                                        <img
                                            class="image-cancel"
                                            src="@/assets/icons/icon-product-cancel.png"
                                        />
                                        <div class="subheading">取消原因</div>
                                        <div class="cancel-reason">
                                            {{ productList.cancelReason }}
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-else-if="productList.structureType == 5">
                                <div class="product-combo-box" :key="productListIndex">
                                    <div class="product-combo-title">
                                        {{ productList.productName }}
                                    </div>
                                    <div
                                        class="product-item"
                                        :class="[
                                            { cancel: productItem.canceled },
                                            { draw: !productItem.canceled && !productItem.ticket },
                                        ]"
                                        v-for="(
                                            productItem, productIndex
                                        ) in productList.comboTickets"
                                        :key="productIndex"
                                    >
                                        <img
                                            class="product-image"
                                            :src="productItem.imageUrl"
                                            alt=""
                                        />
                                        <div class="product-name">
                                            {{ productItem.productName }}
                                        </div>
                                        <div class="quantity">x{{ productItem.quantity }}</div>

                                        <template v-if="productItem.ticket">
                                            <div class="subheading">取餐码</div>
                                            <div class="ticket-desc">
                                                <p
                                                    v-for="(v, i) in productItem.ticketList"
                                                    :key="i"
                                                >
                                                    {{ v }}
                                                </p>
                                            </div>
                                            <div class="place-order-mobile">
                                                下单手机号：{{ productItem.placeOrderMobile }}
                                            </div>
                                        </template>
                                        <template v-else-if="!productItem.canceled">
                                            <div class="wait-text">
                                                出单中
                                                <span class="wait-spot">
                                                    <span>...</span>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-else-if="productItem.canceled">
                                            <img
                                                class="image-cancel"
                                                src="@/assets/icons/icon-product-cancel.png"
                                            />
                                            <div class="subheading">取消原因</div>
                                            <div class="cancel-reason">
                                                {{ productItem.cancelReason }}
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <template
                                v-else-if="
                                    productList.structureType == 10 ||
                                    productList.structureType == 15
                                "
                            >
                                <div
                                    class="product-item"
                                    :class="[
                                        { cancel: productList.canceled },
                                        { draw: !productList.canceled && !productList.ticket },
                                    ]"
                                    :key="productListIndex"
                                >
                                    <img class="product-image" :src="productList.imageUrl" alt="" />
                                    <div class="product-name">
                                        {{ productList.productName }}
                                    </div>
                                    <div class="quantity">x{{ productList.quantity }}</div>
                                    <template v-if="productList.ticket">
                                        <div class="ticket-desc">
                                            <!-- <p>{{ productList.ticket }}</p> -->
                                            <div
                                                class="btn-look"
                                                :class="{ expire: productList.isExpire }"
                                                @click="toMembershipCode(productList)"
                                            >
                                                查看二维码
                                            </div>
                                        </div>
                                        <div
                                            class="place-order-mobile"
                                            v-if="productList.accountAvailableEndTime"
                                        >
                                            过期时间：{{ productList.accountAvailableEndTime }}
                                        </div>
                                    </template>
                                    <template v-else-if="!productList.canceled">
                                        <div class="wait-text">
                                            出单中
                                            <span class="wait-spot">
                                                <span>...</span>
                                            </span>
                                        </div>
                                    </template>
                                    <template v-else-if="productList.canceled">
                                        <img
                                            class="image-cancel"
                                            src="@/assets/icons/icon-product-cancel.png"
                                        />
                                        <div class="subheading">取消原因</div>
                                        <div class="cancel-reason">
                                            {{ productList.cancelReason }}
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-else-if="productList.structureType == 20">
                                <div
                                    class="product-item"
                                    :class="[
                                        { cancel: productList.canceled },
                                        { draw: !productList.canceled && !productList.ticket },
                                    ]"
                                    :key="productListIndex"
                                >
                                    <img class="product-image" :src="productList.imageUrl" alt="" />
                                    <div class="product-name">
                                        {{ productList.productName }}
                                    </div>
                                    <div class="quantity">x{{ productList.quantity }}</div>
                                    <template v-if="productList.ticket">
                                        <div class="ticket-desc">
                                            <!-- <p>{{ productList.ticket }}</p> -->
                                            <div
                                                class="btn-look"
                                                :class="{ expire: productList.isExpire }"
                                                @click="toExpire(productList)"
                                                v-if="productList.ticket == '已发货'"
                                            >
                                                查看物流信息
                                            </div>
                                            <p v-else>{{ productList.ticket }}</p>
                                        </div>
                                    </template>
                                    <template v-else-if="!productList.canceled">
                                        <div class="wait-text">
                                            出单中
                                            <span class="wait-spot">
                                                <span>...</span>
                                            </span>
                                        </div>
                                    </template>
                                    <template v-else-if="productList.canceled">
                                        <img
                                            class="image-cancel"
                                            src="@/assets/icons/icon-product-cancel.png"
                                        />
                                        <div class="subheading">取消原因</div>
                                        <div class="cancel-reason">
                                            {{ productList.cancelReason }}
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </template>
                        <!-- <div class="line detail"></div> -->
                    </template>
                </div>
                <div class="line-up" v-html="orderDetail.content"></div>
            </div>
            <div
                class="order-header wait-rider"
                v-else-if="
                    orderDetail.delivery &&
                    (orderDetail.deliveryStatus == 0 || orderDetail.deliveryStatus == -5) &&
                    orderStatus == 15
                "
            >
                <div class="status-icon"></div>
                <div class="status-text">已出餐，等待骑手接单</div>
                <div class="status-tip bold"></div>
            </div>
            <div
                class="order-header map"
                v-else-if="
                    orderDetail.delivery &&
                    [20, 30].indexOf(orderDetail.deliveryStatus) > -1 &&
                    orderStatus == 15
                "
            >
                <div class="refresh-line" @click="refreshDeliveryInfo">
                    位置更新于{{ deliveryInfo.updateTime }} | 点击刷新
                </div>
                <div class="delivery-map">
                    <div
                        id="delivery-map"
                        v-if="[20, 30].indexOf(orderDetail.deliveryStatus) > -1"
                    ></div>
                </div>
                <div class="delivery-content">
                    <div class="delivery-text">
                        尽快送达「 预计
                        <span class="bold">{{ deliveryInfo.predictDeliveryTimeText }}</span>
                        送达 」
                    </div>
                    <div class="delivery-title" v-if="orderDetail.deliveryStatus == 20">
                        骑手正赶往商家(距{{ deliveryInfo.courier2storeDistance }}km)
                    </div>
                    <div class="delivery-title" v-else-if="orderDetail.deliveryStatus == 30">
                        骑手正赶往送餐地址(距{{ deliveryInfo.courier2receiverDistance }}km)
                    </div>
                    <div class="rider-info">
                        <div class="rider-info-icon"></div>
                        <div class="rider-info-name">
                            {{ deliveryInfo.courierName }} {{ deliveryInfo.courierPhone }}
                        </div>
                        <a :href="'tel:' + deliveryInfo.courierPhone" class="rider-info-a">
                            <div class="rider-info-btn">致电骑手</div>
                        </a>
                    </div>
                </div>
            </div>
            <div
                class="order-header"
                v-else-if="
                    orderDetail.delivery && orderDetail.deliveryStatus == 50 && orderStatus == 15
                "
            >
                <div class="status-icon"></div>
                <div class="status-text">订单已完成</div>
                <div class="status-tip">订单已按时送达</div>
                <div
                    class="btn"
                    v-if="!$store.getters.getExposeMethod.kfcHideNextPay"
                    @click="jixuDian"
                >
                    再次购买
                </div>
            </div>
            <div
                class="order-header"
                v-else-if="
                    orderDetail.delivery && orderDetail.deliveryStatus == 99 && orderStatus == 15
                "
            >
                <template v-if="!showPickUpMealsCode">
                    <div class="status-icon"></div>
                    <div class="status-text">当前运力紧张，暂无骑手接单</div>
                    <div class="status-tip" v-if="isNotInServiceTime">
                        由于目前没有在线客服值班，请于8点后联系在线客服。也可以自行前往门店取餐，外送服务费请联系在线客服退款。
                    </div>
                    <div class="status-tip" v-else>请联系客服处理</div>
                    <div class="btn-list">
                        <div
                            class="btn"
                            v-if="isNotInServiceTime"
                            @click="showPickUpMealsCode = true"
                        >
                            查看取餐码
                        </div>
                        <div
                            class="btn"
                            v-if="!$store.getters.getExposeMethod.kfcHideNextPay"
                            @click="contact"
                        >
                            联系客服
                        </div>
                    </div>
                </template>
                <template v-if="showPickUpMealsCode">
                    <div class="product-list" :class="{ 'hide-radius': orderDetail.content }">
                        <template v-if="PickUpMealsCodeList && PickUpMealsCodeList.length">
                            <template
                                v-for="(productList, productListIndex) in PickUpMealsCodeList"
                            >
                                <template v-if="productList.structureType == 0">
                                    <div
                                        class="product-item"
                                        :class="[
                                            { cancel: productList.canceled },
                                            { draw: !productList.canceled && !productList.ticket },
                                        ]"
                                        :key="productListIndex"
                                    >
                                        <img
                                            class="product-image"
                                            :src="productList.imageUrl"
                                            alt=""
                                        />
                                        <div class="product-name">
                                            {{ productList.productName }}
                                        </div>
                                        <div class="quantity">x{{ productList.quantity }}</div>

                                        <template v-if="productList.ticket">
                                            <div class="subheading">取餐码</div>
                                            <div class="ticket-desc">
                                                <p
                                                    v-for="(v, i) in productList.ticketList"
                                                    :key="i"
                                                >
                                                    {{ v }}
                                                </p>
                                            </div>
                                            <div class="place-order-mobile">
                                                下单手机号：{{ productList.placeOrderMobile }}
                                            </div>
                                        </template>
                                        <template v-else-if="!productList.canceled">
                                            <div class="wait-text">
                                                出单中
                                                <span class="wait-spot">
                                                    <span>...</span>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-else-if="productList.canceled">
                                            <img
                                                class="image-cancel"
                                                src="@/assets/icons/icon-product-cancel.png"
                                            />
                                            <div class="subheading">取消原因</div>
                                            <div class="cancel-reason">
                                                {{ productList.cancelReason }}
                                            </div>
                                        </template>
                                    </div>
                                </template>
                                <template v-else-if="productList.structureType == 5">
                                    <div class="product-combo-box" :key="productListIndex">
                                        <div class="product-combo-title">
                                            {{ productList.productName }}
                                        </div>
                                        <div
                                            class="product-item"
                                            :class="[
                                                { cancel: productItem.canceled },
                                                {
                                                    draw:
                                                        !productItem.canceled &&
                                                        !productItem.ticket,
                                                },
                                            ]"
                                            v-for="(
                                                productItem, productIndex
                                            ) in productList.comboTickets"
                                            :key="productIndex"
                                        >
                                            <img
                                                class="product-image"
                                                :src="productItem.imageUrl"
                                                alt=""
                                            />
                                            <div class="product-name">
                                                {{ productItem.productName }}
                                            </div>
                                            <div class="quantity">x{{ productItem.quantity }}</div>

                                            <template v-if="productItem.ticket">
                                                <div class="subheading">取餐码</div>
                                                <div class="ticket-desc">
                                                    <p
                                                        v-for="(v, i) in productItem.ticketList"
                                                        :key="i"
                                                    >
                                                        {{ v }}
                                                    </p>
                                                </div>
                                                <div class="place-order-mobile">
                                                    下单手机号：{{ productItem.placeOrderMobile }}
                                                </div>
                                            </template>
                                            <template v-else-if="!productItem.canceled">
                                                <div class="wait-text">
                                                    出单中
                                                    <span class="wait-spot">
                                                        <span>...</span>
                                                    </span>
                                                </div>
                                            </template>
                                            <template v-else-if="productItem.canceled">
                                                <img
                                                    class="image-cancel"
                                                    src="@/assets/icons/icon-product-cancel.png"
                                                />
                                                <div class="subheading">取消原因</div>
                                                <div class="cancel-reason">
                                                    {{ productItem.cancelReason }}
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                                <template
                                    v-else-if="
                                        productList.structureType == 10 ||
                                        productList.structureType == 15
                                    "
                                >
                                    <div
                                        class="product-item"
                                        :class="[
                                            { cancel: productList.canceled },
                                            { draw: !productList.canceled && !productList.ticket },
                                        ]"
                                        :key="productListIndex"
                                    >
                                        <img
                                            class="product-image"
                                            :src="productList.imageUrl"
                                            alt=""
                                        />
                                        <div class="product-name">
                                            {{ productList.productName }}
                                        </div>
                                        <div class="quantity">x{{ productList.quantity }}</div>
                                        <template v-if="productList.ticket">
                                            <div class="ticket-desc">
                                                <!-- <p>{{ productList.ticket }}</p> -->
                                                <div
                                                    class="btn-look"
                                                    :class="{ expire: productList.isExpire }"
                                                    @click="toMembershipCode(productList)"
                                                >
                                                    查看二维码
                                                </div>
                                            </div>
                                            <div
                                                class="place-order-mobile"
                                                v-if="productList.accountAvailableEndTime"
                                            >
                                                过期时间：{{ productList.accountAvailableEndTime }}
                                            </div>
                                        </template>
                                        <template v-else-if="!productList.canceled">
                                            <div class="wait-text">
                                                出单中
                                                <span class="wait-spot">
                                                    <span>...</span>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-else-if="productList.canceled">
                                            <img
                                                class="image-cancel"
                                                src="@/assets/icons/icon-product-cancel.png"
                                            />
                                            <div class="subheading">取消原因</div>
                                            <div class="cancel-reason">
                                                {{ productList.cancelReason }}
                                            </div>
                                        </template>
                                    </div>
                                </template>
                                <template v-else-if="productList.structureType == 20">
                                    <div
                                        class="product-item"
                                        :class="[
                                            { cancel: productList.canceled },
                                            { draw: !productList.canceled && !productList.ticket },
                                        ]"
                                        :key="productListIndex"
                                    >
                                        <img
                                            class="product-image"
                                            :src="productList.imageUrl"
                                            alt=""
                                        />
                                        <div class="product-name">
                                            {{ productList.productName }}
                                        </div>
                                        <div class="quantity">x{{ productList.quantity }}</div>
                                        <template v-if="productList.ticket">
                                            <div class="ticket-desc">
                                                <!-- <p>{{ productList.ticket }}</p> -->
                                                <div
                                                    class="btn-look"
                                                    :class="{ expire: productList.isExpire }"
                                                    @click="toExpire(productList)"
                                                    v-if="productList.ticket == '已发货'"
                                                >
                                                    查看物流信息
                                                </div>
                                                <p v-else>{{ productList.ticket }}</p>
                                            </div>
                                        </template>
                                        <template v-else-if="!productList.canceled">
                                            <div class="wait-text">
                                                出单中
                                                <span class="wait-spot">
                                                    <span>...</span>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-else-if="productList.canceled">
                                            <img
                                                class="image-cancel"
                                                src="@/assets/icons/icon-product-cancel.png"
                                            />
                                            <div class="subheading">取消原因</div>
                                            <div class="cancel-reason">
                                                {{ productList.cancelReason }}
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </template>
                            <!-- <div class="line detail"></div> -->
                        </template>
                    </div>
                </template>
            </div>
            <div class="type-20-tip" v-if="orderType == 20">
                为给您最优惠价格，已创建2个订单
                <img src="@/assets/img/horn.png" />
            </div>
        </template>
        <div class="model">
            <div class="line line-title border-bottom">
                <div class="line-item title">餐品明细</div>
            </div>
            <div class="product-list">
                <template v-if="orderDetail.kfcPlaceOrder && orderDetail.kfcPlaceOrder.items">
                    <template>
                        <div
                            class="line detail"
                            :class="[
                                { canceled: item.canceled },
                                { 'hide-origin-price': item.price == item.originPrice },
                                {
                                    'has-sku':
                                        item.condimentRoundList &&
                                        item.condimentRoundList.length > 0,
                                },
                            ]"
                            v-for="(item, index) in orderDetail.kfcPlaceOrder.items"
                            :key="'detail-' + index"
                        >
                            <img src="@/assets/icons/icon-order-refund.png" class="refund-img" />
                            <img class="img" :src="item.imageUrl" />
                            <div
                                class="name"
                                :class="{ favour: item.firstActivity || item.cardCode }"
                            >
                                <p class="p">{{ item.productName }}</p>
                            </div>
                            <div
                                class="sku-box"
                                v-if="item.condimentRoundList && item.condimentRoundList.length > 0"
                                :class="{ open: item.skuOpenStatus }"
                                @click="openSkuText(index)"
                            >
                                <div
                                    class="sku-item"
                                    v-for="(v, i) in item.condimentRoundList"
                                    :key="i"
                                >
                                    {{ v.productName }} * {{ v.quantity }}
                                </div>
                            </div>
                            <div class="num">x{{ item.quantity }}</div>
                            <div class="price">{{ item.price }}</div>
                            <div class="origin-price">{{ item.originPrice }}</div>
                        </div>
                    </template>
                </template>
                <div class="line detail" v-if="orderDetail.kfcTakeoutAddress && !isExpress">
                    <img class="img" :src="require('@/assets/img/icon-takeout.png')" />
                    <div class="name">外送服务费</div>
                    <div class="num">x1</div>
                    <div class="price out">{{ orderDetail.takeoutPrice }}</div>
                </div>
            </div>
            <div class="line total border-top">
                <div class="value">
                    <p>
                        <template v-if="$store.getters.getExposeMethod.showOriginalPrice">
                            <span>共{{ totalNum }}件</span>
                            <span class="m-l-20">实付</span>
                            <span class="num m-l-20 price">{{ orderDetail.marketUnitPrice }}</span>
                            ，
                            <span>下单后返</span>
                            <span class="num m-l-20 price">{{ renderTotalPrice }}</span>
                        </template>
                        <template v-else>
                            <span>共{{ totalNum }}件</span>
                            <span class="price">{{ orderDetail.totalPrice }}</span>
                        </template>
                        <span class="refund-price" v-if="orderDetail.refundPrice">
                            {{ orderDetail.refundPrice }}
                        </span>
                        <span
                            class="refund-price-tip"
                            v-if="orderDetail.refundPrice && showRefundPriceTipStatus"
                            @click="showRefundPriceTip"
                        >
                            ?
                        </span>
                    </p>
                    <p v-if="xiaoBuDianIntegral">
                        <span>积分：</span>
                        <span class="integral">{{ xiaoBuDianIntegral }}</span>
                    </p>
                    <p v-else-if="jiMiWuXianIntegral">
                        <span>能量：</span>
                        <span class="integral">{{ jiMiWuXianIntegral }}</span>
                    </p>
                    <p v-if="orderDetail.remark">{{ orderDetail.remark }}</p>
                </div>
            </div>
        </div>
        <div class="model">
            <div class="line line-title">
                <div class="line-item title">订单信息</div>
            </div>
            <div
                class="line order-information"
                v-if="orderStatus != -5 && orderStatus != 0 && isTakeout"
            >
                <div class="line-item title">送达时间</div>
                <div class="line-item value" v-if="orderStatus == 10 || orderStatus == 5">
                    预计 {{ orderDetail.takeoutShortTime }} 送达
                </div>
                <div class="line-item value" v-else-if="orderStatus == 15">订单已按时送达</div>
            </div>
            <div class="line order-information" v-if="orderStatus != -5 && orderDetail.delivery">
                <div class="line-item title">送达时间</div>
                <div class="line-item value" v-if="orderDetail.predictDeliveryTime">
                    预计 {{ orderDetail.predictDeliveryTime }} 送达
                </div>
                <!-- <div class="line-item value" v-else-if="orderStatus == 15">订单已按时送达</div> -->
            </div>
            <div
                class="line order-information address-info-box"
                v-if="orderDetail.kfcTakeoutAddress"
            >
                <div class="line-item title">送餐地址</div>
                <div class="line-item value" v-if="orderDetail.kfcTakeoutAddress">
                    <div class="address">
                        {{ orderDetail.kfcTakeoutAddress.mainAddress
                        }}{{ orderDetail.kfcTakeoutAddress.detailAddress }}
                    </div>
                    <!-- <div class="moblie">{{orderDetail.kfcTakeoutAddress.name}} {{orderDetail.kfcTakeoutAddress.mobile}}</div> -->
                </div>
            </div>
            <div
                class="line order-information store-info"
                v-if="(orderDetail.kfcPlaceOrder || orderDetail._kfcPlaceOrder) && !isTakeout"
            >
                <div class="line-item title" v-if="orderDetail.delivery">出餐门店</div>
                <div class="line-item title" v-else>取餐门店</div>
                <div class="line-item value">
                    <div class="name">
                        {{
                            orderDetail.kfcPlaceOrder
                                ? orderDetail.kfcPlaceOrder.storeName
                                : orderDetail._kfcPlaceOrder.storeName
                        }}({{
                            orderDetail.kfcPlaceOrder
                                ? orderDetail.kfcPlaceOrder.storeAddress
                                : orderDetail._kfcPlaceOrder.storeAddress
                        }})
                    </div>
                </div>
            </div>
            <div class="line order-information" v-if="orderStatus != -5 && orderDetail.paymentTime">
                <div class="line-item title">下单时间</div>
                <div class="line-item value">{{ orderDetail.paymentTime }}</div>
            </div>
            <div class="line order-information order-copy">
                <div class="line-item title">订单号</div>
                <div class="line-item value">
                    {{ orderNo || orderDetail.orderNo }}
                    <span v-clipboard:copy="orderDetail.orderNo" v-clipboard:success="copySuccess">
                        复制
                    </span>
                </div>
            </div>
            <div
                class="line order-information"
                v-if="(orderDetail.kfcPlaceOrder || orderDetail._kfcPlaceOrder) && !isTakeout"
            >
                <div class="line-item title">就餐方式</div>
                <div class="line-item value" v-if="orderDetail.delivery">美团配送</div>
                <div class="line-item value" v-else>
                    {{
                        (orderDetail.kfcPlaceOrder
                            ? orderDetail.kfcPlaceOrder.eatType
                            : orderDetail._kfcPlaceOrder.eatType) == 1
                            ? '店内就餐'
                            : '打包带走'
                    }}
                </div>
            </div>
            <div
                class="line order-information"
                v-if="!(orderDetail.paymentTime && !orderDetail.paySubTypeDesc)"
            >
                <div class="line-item title">支付方式</div>
                <div class="line-item value">{{ orderDetail.paySubTypeDesc }}</div>
            </div>
            <div class="line order-information">
                <div class="line-item title">联系方式</div>
                <div class="line-item value">{{ orderDetail.userMobile }}</div>
            </div>
            <div class="line user-remark">
                <div class="line-item title">备注</div>
                <div class="line-item value">
                    {{ orderDetail.userRemark ? orderDetail.userRemark : '暂无' }}
                </div>
            </div>
            <div class="line order-information btn-line">
                <div class="line-item value">
                    <div class="btn" v-if="orderStatus == 0" @click="cancelOrder">取消订单</div>
                    <div class="btn" v-else-if="orderStatus == 5" @click="reminder">催单</div>
                    <div class="btn contact" @click="contact">联系客服</div>
                </div>
            </div>
        </div>
        <div class="tip">
            <p class="title">友情提示：</p>
            <p class="p">
                1、如遇到商品售罄，将导致您无法取到所定食物，系统将自动为您安排退款，1个工作日内到账；
            </p>
            <p class="p">2、如遇更多问题，请及时联系在线客服处理。</p>
        </div>
    </div>
</template>
<script type="text/javascript">
window._AMapSecurityConfig = {
    securityJsCode: '69c8fb6d1f688eeae7073f611778c667',
};
</script>
<script>
import pay from 'pay';
import {
    GET_KFC_ORDER_DETAIL,
    GET_KFC_ORDER_DETAIL_BLEND,
    GET_KFC_ORDER_INFO_OF_ORDER_NO,
    CANCEL_NOT_PAID_ORDER,
    GET_ORDER_PRODUCT_ITEM,
} from 'api';
import {
    PAGE_ORDER_INFO,
    PAGE_ROOT,
    PAGE_CUSTOMER_SERVICE,
    PAGE_RESTAURANT_SELECT,
    PAGE_EAT_TYPE,
    PAGE_ADDRESS_LIST,
    PAGE_ORDER_PAY,
    PAGE_WALLET,
    PAGE_EXPRESS,
} from 'page';
import { QZ_CURRENT_SCENE } from 'constant';
import number from '@/lib/number';
import moment from 'moment';
import CustomerService from '@/components/customerService/btn.vue';
let PAGE_GUID = '';

let deliveryMap = null;
let markerCourier = null;
let markerReceiver = null;
let markerStore = null;

export default {
    data() {
        return {
            orderId: null,
            orderNo: null,
            currentTime: Number(moment().format('x')),
            complete2HTime: 0,
            sourceType: 0,
            orderStatus: null,
            titleArr: {
                0: '订单待支付',
                '-5': '订单已取消',
                5: '排队中',
                15: '订单已完成',
            },
            orderType: 10,
            orderName: '',
            orderDetail: {},
            time: '15:00',
            autoHeight: true,
            // autoHeight: false,
            showItemNum: 2,
            isOpenBack: false,
            updatePageTimer: null,
            deliveryInfoTimer: null,
            countTime: null, // 倒计时
            isClickJX: false,
            isTakeout: false,
            ticketList: [],
            // 返回首页按钮
            showHome: false,
            totalNum: 0,
            // 取餐码列表
            PickUpMealsCodeList: [],
            showRefundPriceTipStatus: false,
            // 配送信息
            deliveryInfo: {},
            // 是否快递订单
            isExpress: false,
            // 当前是否在客服不在岗时间
            isNotInServiceTime: false,
            // 显示取餐码
            showPickUpMealsCode: false,
        };
    },
    computed: {
        renderTotalPrice() {
            if (
                !this.orderDetail ||
                !this.orderDetail.kfcPlaceOrder ||
                !this.orderDetail.kfcPlaceOrder.items.length
            ) {
                return 0;
            }
            let result = 0;
            for (let i = 0; i < this.orderDetail.kfcPlaceOrder.items.length; i++) {
                const item = this.orderDetail.kfcPlaceOrder.items[i];
                let renderPrice = number.operation(`${item.originPrice}-${item.price}`);
                let renderTotalPrice = number.operation(`${renderPrice}*${item.quantity}`);
                result = number.operation(`${renderTotalPrice}+${result}`);
            }
            return result;
        },
        xiaoBuDianIntegral() {
            if (this.$store.getters.getExposeMethod.customizedXiaoBuDian) {
                let result = 0;
                if (
                    !this.orderDetail ||
                    !this.orderDetail.kfcPlaceOrder ||
                    !this.orderDetail.kfcPlaceOrder.items
                ) {
                    return result;
                }
                for (let i = 0; i < this.orderDetail.kfcPlaceOrder.items.length; i++) {
                    const item = this.orderDetail.kfcPlaceOrder.items[i];
                    let renderPrice = +number.operation(`${item.originPrice}-${item.price}`);
                    let renderTotalPrice = +number.operation(`${renderPrice}*${item.quantity}`);
                    result = +number.operation(`${renderTotalPrice}+${result}`);
                }

                return +number.operation(`${result}*20`);
            } else {
                return 0;
            }
        },
        jiMiWuXianIntegral() {
            if (this.$store.getters.getExposeMethod.customizedJiMiWuXian) {
                let result = 0;
                if (
                    !this.orderDetail ||
                    !this.orderDetail.kfcPlaceOrder ||
                    !this.orderDetail.kfcPlaceOrder.items
                ) {
                    return result;
                }
                for (let i = 0; i < this.orderDetail.kfcPlaceOrder.items.length; i++) {
                    const item = this.orderDetail.kfcPlaceOrder.items[i];
                    let renderPrice = +number.operation(`${item.originPrice}-${item.price}`);
                    let renderTotalPrice = +number.operation(`${renderPrice}*${item.quantity}`);
                    result = +number.operation(`${renderTotalPrice}+${result}`);
                }

                return +number.operation(`${result}*10`);
            } else {
                return 0;
            }
        },
    },
    components: {
        CustomerService,
    },
    filters: {
        payTypeFilter(val) {
            if (val == null) {
                return '未支付';
            } else if (val == 1) {
                return '微信支付';
            } else if (val == 2) {
                return '支付宝支付';
            }
        },
    },
    created() {
        this.orderNo = this.$route.query.orderNo || 0;
        this.orderId = this.$route.query.orderId || 0;
        this.sourceType = this.$route.query.sourceType || 0;
        this.orderType = this.$route.query.orderType || 10;
        this.showHome = this.$route.query.showHome == 'true';
        this.isNotInServiceTime = moment().isBetween(
            moment().startOf('day').add(6, 'hours'),
            moment().startOf('day').add(22, 'hours'),
            null,
            '[]'
        );
        const isQskApp = /ZZC(Android|IOS)\/black\//.test(navigator.userAgent);
        if (isQskApp) {
            require('@/lib/qsk/qsk.app.mini-1.0.js');
            if (window.QskAppSdk && window.QskAppSdk.isQskApp() && this.sourceType != 2) {
                window.QskAppSdk.customizeBackEvent({
                    callbackFn: () => {
                        // 定义返回按钮后会执⾏ callbackFn
                        window.QskAppSdk.backToVipTab();
                    },
                    NoGestures: true,
                    // 是否阻⽌左滑返回
                });
            }
        }
        if (this.$route.query.backIndex) {
            this.fobiddeBack();
        } else {
            this.enableBack();
        }
        this.getData();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from && from.path == PAGE_EAT_TYPE && from.query.backType == 2) {
                if (vm.$store.getters.isWechatMin) {
                    wx.miniProgram.navigateBack({
                        delta: 1,
                    });
                } else {
                    for (let i = 100; i > 0; i--) {
                        window.history.go(-i);
                    }
                }
            }
            PAGE_GUID = vm.$util.guid();
        });
    },
    methods: {
        getData() {
            if (this.orderNo) {
                this.$util
                    .get(`${GET_KFC_ORDER_INFO_OF_ORDER_NO}?orderNo=${this.orderNo}`)
                    .then(res => {
                        if (res.success) {
                            this.getDataNext(res);
                        }
                    });
                return;
            }
            let url = '';
            if (this.orderType == 20) {
                url = `${GET_KFC_ORDER_DETAIL_BLEND}?orderId=${this.orderId}`;
            } else {
                url = `${GET_KFC_ORDER_DETAIL}/${this.orderId}`;
            }
            this.$util.get(url).then(res => {
                if (res.success) {
                    this.getDataNext(res);
                }
            });
        },
        // 获取数据成功处理
        getDataNext(res) {
            this.orderStatus = res.data.status;
            this.orderName = res.data.statusDesc;
            this.orderDetail = res.data;
            this.isTakeout = res.data.takeout;
            this.totalNum = 0;
            if (this.orderType == 20) {
                this.orderDetail._kfcPlaceOrder = res.data.kfcOrders[0].kfcPlaceOrder;
                this.orderDetail.userMobile = res.data.kfcOrders[0].userMobile;
                for (let i = 0; i < res.data.kfcOrders.length; i++) {
                    const item = res.data.kfcOrders[i];
                    for (let j = 0; j < item.kfcPlaceOrder.items.length; j++) {
                        const v = item.kfcPlaceOrder.items[j];
                        this.totalNum += v.quantity;
                    }
                }
            } else {
                for (let i = 0; i < res.data.kfcPlaceOrder.items.length; i++) {
                    const v = res.data.kfcPlaceOrder.items[i];
                    this.totalNum += v.quantity;
                    if (v.express) {
                        this.isExpress = true;
                    }
                }
            }
            if (this.isTakeout) {
                this.orderDetail.takeoutAddress = JSON.parse(this.orderDetail.takeoutAddress);
                if (this.orderDetail.drawTime) {
                    this.orderDetail.takeoutTime = moment(this.orderDetail.drawTime)
                        .add(30, 'm')
                        .format('YYYY-MM-DD HH:mm:ss');
                    this.orderDetail.takeoutShortTime =
                        moment(this.orderDetail.drawTime).add(30, 'm').format('x') <
                        moment(moment().format('YYYY-MM-DD 23:59:59')).format('x')
                            ? '今天'
                            : '明天';
                    this.orderDetail.takeoutShortTime += moment(this.orderDetail.drawTime)
                        .add(30, 'm')
                        .format('HH:mm');
                } else {
                    this.orderDetail.takeoutTime = moment(this.orderDetail.paymentTime)
                        .add(40, 'm')
                        .format('YYYY-MM-DD HH:mm:ss');
                    this.orderDetail.takeoutShortTime =
                        moment(this.orderDetail.paymentTime).add(40, 'm').format('x') <
                        moment(moment().format('YYYY-MM-DD 23:59:59')).format('x')
                            ? '今天'
                            : '明天';
                    this.orderDetail.takeoutShortTime += moment(this.orderDetail.paymentTime)
                        .add(40, 'm')
                        .format('HH:mm');
                }
            }
            // 待支付倒计时
            if (res.data.status == 0) {
                let endTime = new Date(res.data.paymentExpireTime.replace(/-/g, '/')).getTime();
                let nowTime = new Date().getTime();
                if (res.data.expireSeconds || res.data.expireSeconds == 0) {
                    if (res.data.expireSeconds <= 0) {
                        this.orderStatus = -5;
                    } else {
                        this.timeDaojishi(res.data.expireSeconds * 1000);
                    }
                } else {
                    if (nowTime >= endTime) {
                        this.orderStatus = -5;
                    } else {
                        this.timeDaojishi(endTime - nowTime);
                    }
                }
                // 如果是唤醒创建订单的1分钟 定时刷新
                if (moment().format('X') - moment(res.data.createTime).format('X') < 3 * 60) {
                    if (this._isDestroyed) {
                        console.log('离开页面销毁数据', PAGE_GUID, res.data.id);
                        return;
                    }
                    // TODO
                    // console.log('准备拉取下一次数据', PAGE_GUID, res.data.id);
                    if (this.updatePageTimer) {
                        clearTimeout(this.updatePageTimer);
                    }
                    this.updatePageTimer = setTimeout(() => {
                        this.getData();
                    }, 2000);
                }
            } else if (res.data.status == 5) {
                if (this._isDestroyed) {
                    console.log('离开页面销毁数据', PAGE_GUID, res.data.id);
                    return;
                }
                // TODO
                // console.log('准备拉取下一次数据', PAGE_GUID, res.data.id);
                if (this.updatePageTimer) {
                    clearTimeout(this.updatePageTimer);
                }
                this.getPickUpMealsCode(res.data.orderNo);
                this.updatePageTimer = setTimeout(() => {
                    this.getData();
                }, 5000);
            } else if (res.data.status == 15) {
                this.getPickUpMealsCode(res.data.orderNo);
                if (
                    res.data.deliveryStatus != 50 &&
                    res.data.deliveryStatus != 99 &&
                    res.data.delivery
                ) {
                    this.setDeliveryInfoTimer();
                }
            }
            // 显示退款说明
            if (
                res.data.kfcPlaceOrder &&
                res.data.kfcPlaceOrder.items &&
                res.data.kfcPlaceOrder.items.length
            ) {
                for (let i = 0; i < res.data.kfcPlaceOrder.items.length; i++) {
                    const v = res.data.kfcPlaceOrder.items[i];
                    if (v.productAvgPrice && v.canceled) {
                        this.showRefundPriceTipStatus = true;
                    }
                }
            }

            let tempTicketList = [];
            if (this.orderDetail.ticket) {
                let tempList = this.orderDetail.ticket.split(',');
                for (let i = 0; i < tempList.length; i++) {
                    const item = tempList[i];
                    if (item) {
                        tempTicketList.push({
                            ticket: item.split('|')[0],
                            mobile: item.split('|')[1],
                        });
                    }
                }
            }

            this.ticketList = tempTicketList;
            if (this.orderDetail.drawTime) {
                this.complete2HTime = moment(this.orderDetail.drawTime).add(50, 'm').format('x');
                // this.complete2HTime = moment(this.orderDetail.drawTime).add(2, 'H').format('x')
            }
            if (this.currentTime < this.complete2HTime && this.isTakeout && res.data.status != -5) {
                this.orderStatus = 10;
            }
        },
        // 设置同步配送信息定时器
        setDeliveryInfoTimer() {
            this.getDeliveryInfoTime();
            if (this.deliveryInfoTimer) {
                clearInterval(this.deliveryInfoTimer);
            }
            this.deliveryInfoTimer = setInterval(() => {
                if (this._isDestroyed) {
                    if (this.deliveryInfoTimer) {
                        clearInterval(this.deliveryInfoTimer);
                    }
                } else if (
                    this.orderDetail.deliveryStatus == 50 ||
                    this.orderDetail.deliveryStatus == 99
                ) {
                    if (this.deliveryInfoTimer) {
                        clearInterval(this.deliveryInfoTimer);
                    }
                } else {
                    this.getDeliveryInfoTime();
                }
            }, 15 * 1000);
        },
        // 刷新位置
        refreshDeliveryInfo() {
            this.$util.debounce(this.getDeliveryInfoTime)();
        },
        // 获取订单配送信息
        getDeliveryInfoTime() {
            this.$util
                .get('/api/kfcOrder/peiSong/getRiderLocation', {
                    orderNo: this.orderNo,
                })
                .then(res => {
                    if (res.success) {
                        this.orderDetail.deliveryStatus = res.data.orderDeliveryStatus;
                        this.deliveryInfo = Object.assign(res.data, {
                            predictDeliveryTimeText: moment(res.data.predictDeliveryTime).format(
                                'HH:mm'
                            ),
                            updateTime: moment().format('HH:mm'),
                        });
                        if ([20, 30].indexOf(res.data.orderDeliveryStatus) > -1) {
                            const {
                                courierLat,
                                courierLng,
                                receiverLat,
                                receiverLng,
                                storeLat,
                                storeLng,
                            } = res.data;
                            if (!deliveryMap) {
                                this.$util.dynamicLoadJs(
                                    'https://webapi.amap.com/loader.js',
                                    () => {
                                        AMapLoader.reset();
                                        AMapLoader.load({
                                            key: '3ee4bcf8c99fc1bfe930857af29ad5d1', // 申请好的Web端开发者Key，首次调用 load 时必填
                                            version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                                        }).then(AMap => {
                                            let centerLat = (+receiverLat + +storeLat) / 2;
                                            let centerLng = (+receiverLng + +storeLng) / 2;
                                            console.log(centerLng, centerLat);
                                            deliveryMap = new AMap.Map('delivery-map', {
                                                viewMode: '2D', // 默认使用 2D 模式
                                                zoom: 13, //初始化地图层级
                                                center: [centerLng, centerLat], //初始化地图中心点
                                                // center: [courierLng, courierLat], //初始化地图中心点
                                            });

                                            markerCourier = new AMap.Marker({
                                                position: new AMap.LngLat(courierLng, courierLat),
                                                content: `<div class="delivery-courier"></div>`, // 将 html 传给 content
                                                offset: new AMap.Pixel(0, 0), // 以 icon 的 [center bottom] 为原点
                                            });
                                            deliveryMap.add(markerCourier);

                                            markerReceiver = new AMap.Marker({
                                                position: new AMap.LngLat(receiverLng, receiverLat),
                                                content: `<div class="delivery-receiver"></div>`, // 将 html 传给 content
                                                offset: new AMap.Pixel(0, 0), // 以 icon 的 [center bottom] 为原点
                                            });
                                            deliveryMap.add(markerReceiver);

                                            markerStore = new AMap.Marker({
                                                position: new AMap.LngLat(storeLng, storeLat),
                                                content: `<div class="delivery-store"></div>`, // 将 html 传给 content
                                                offset: new AMap.Pixel(0, 0), // 以 icon 的 [center bottom] 为原点
                                            });
                                            deliveryMap.add(markerStore);
                                        });
                                    }
                                );
                            } else {
                                markerCourier.setPosition([courierLng, courierLat]);
                            }
                        } else if ([50, 99].indexOf(res.data.orderDeliveryStatus) > -1) {
                            // document.getElementById('delivery-map')?.remove();
                            // deliveryMap.destroy();
                            this.$nextTick(() => {
                                this.getData();
                            });
                        }
                    }
                });
        },
        // 获取取餐码
        getPickUpMealsCode(orderNo) {
            this.$util
                .get(GET_ORDER_PRODUCT_ITEM, {
                    orderNo,
                })
                .then(res => {
                    if (res.success) {
                        for (let i = 0; i < res.data.length; i++) {
                            const item = res.data[i];
                            if (0 == item.structureType && item.ticket) {
                                let tempTicket = item.ticket.split(',');
                                let mobileList = [];
                                item.ticketList = [];
                                for (let k = 0; k < tempTicket.length; k++) {
                                    const t = tempTicket[k];
                                    item.ticketList.push(t.split('|')[0]);
                                    mobileList.push(t.split('|')[1]);
                                }
                                item.placeOrderMobile = mobileList.join(',');
                            } else if (5 == item.structureType) {
                                for (let j = 0; j < item.comboTickets.length; j++) {
                                    const v = item.comboTickets[j];
                                    if (v.ticket) {
                                        let tempTicket = v.ticket.split(',');
                                        let mobileList = [];
                                        v.ticketList = [];
                                        for (let k = 0; k < tempTicket.length; k++) {
                                            const t = tempTicket[k];
                                            v.ticketList.push(t.split('|')[0]);
                                            mobileList.push(t.split('|')[1]);
                                        }
                                        v.placeOrderMobile = mobileList.join(',');
                                    }
                                }
                            } else if (10 == item.structureType || 15 == item.structureType) {
                                if (item.accountAvailableEndTime) {
                                    if (moment().isAfter(moment(item.accountAvailableEndTime))) {
                                        item.isExpire = true;
                                    } else {
                                        item.isExpire = false;
                                    }
                                } else {
                                    item.isExpire = false;
                                }
                            }
                        }
                        this.PickUpMealsCodeList = res.data;
                    }
                });
        },
        // 倒计时
        timeDaojishi(t) {
            if (this._isDestroyed) {
                return;
            }
            if (t > 1000) {
                let fen = parseInt(t / 60000);
                let miao = parseInt((t % 60000) / 1000);
                if (fen < 10) {
                    fen = '0' + fen;
                }
                if (miao < 10) {
                    miao = '0' + miao;
                }
                this.time = fen + ':' + miao;
                if (this.countTime) {
                    clearTimeout(this.countTime);
                }
                this.countTime = setTimeout(() => {
                    this.timeDaojishi(t - 1000);
                }, 1000);
            } else {
                this.orderStatus = -5;
            }
        },
        // 展开更多
        openList(index) {
            if (index > -1) {
                this.orderDetail.kfcOrders[index].openStatus = true;
                this.$forceUpdate();
            } else {
                this.autoHeight = true;
            }
        },
        // 收起更多
        closeList(index) {
            if (index > -1) {
                this.orderDetail.kfcOrders[index].openStatus = false;
                this.$forceUpdate();
            } else {
                this.autoHeight = false;
            }
        },
        // 成功复制
        copySuccess() {
            this.$toast('复制成功');
        },
        // 支付
        payOrder() {
            if (this.$store.getters.getExposeMethod.expandName == 'QiShiKa') {
                return this.$util.pageJump(PAGE_ORDER_PAY, {
                    orderType: this.orderType,
                    orderNo: this.orderDetail.orderNo,
                });
            } else if (this.sourceType == 4) {
                window.history.go(-3);
                return;
            }
            // 添加支付参数
            pay({
                orderType: this.orderType,
                orderId: this.orderDetail.id,
                orderNo: this.orderDetail.orderNo,
                // returnUrl: `${window.location.origin}${PAGE_ORDER_INFO}?orderId=${this.orderDetail.id}&orderType=${this.orderType}`
            }).then(payRes => {
                if (payRes.success && payRes.source == 'WX') {
                    this.$util.pageJump({
                        path: PAGE_ORDER_INFO,
                        query: {
                            orderId: this.orderDetail.id,
                            orderType: this.orderType,
                        },
                        config: {
                            jumpType: 1,
                        },
                    });
                }
            });
        },
        // 继续点餐
        jixuDian() {
            this.isClickJX = true;
            if (this.sourceType == 0) {
                this.$util.pageBack(1);
            } else if (this.sourceType == 1) {
                this.$util.pageJump({
                    path: this.isTakeout ? PAGE_ADDRESS_LIST : PAGE_RESTAURANT_SELECT,
                    query: {
                        type: 1,
                    },
                    config: {
                        jumpType: 1,
                    },
                });
            } else if (this.sourceType == 2) {
                this.$router.currentRoute.params.backToIndex = true;
                this.$util.pageBack(1);
            } else if (this.sourceType == 3) {
                this.$util.pageJump({
                    path: PAGE_ROOT,
                    config: {
                        jumpType: 1,
                    },
                });
            }
        },
        openSkuText(index) {
            if (this.orderDetail.kfcPlaceOrder.items[index].skuOpenStatus) {
                this.orderDetail.kfcPlaceOrder.items[index].skuOpenStatus = false;
            } else {
                this.orderDetail.kfcPlaceOrder.items[index].skuOpenStatus = true;
            }
            this.$forceUpdate();
        },
        // 取消订单
        cancelOrder() {
            this.$util
                .post(CANCEL_NOT_PAID_ORDER, { orderNo: this.orderDetail.orderNo })
                .then(res => {
                    if (res.success) {
                        this.$toast('订单已取消');
                        this.getData();
                    }
                });
        },
        // 催单
        reminder() {
            setTimeout(() => {
                this.$toast('催单成功！订单会加急处理哦，请耐心等待~');
            }, 200);
        },
        // 联系客服
        contact() {
            this.$util.pageJump(PAGE_CUSTOMER_SERVICE, {
                orderNo: this.orderDetail.orderNo,
            });
            // this.$customerService.open();
        },
        // 去首页
        toHome() {
            this.$util.pageJump({
                path: PAGE_ROOT,
                config: {
                    jumpType: 1,
                },
            });
        },
        //防止页面后退
        fobiddeBack() {
            if (!this.isOpenBack) {
                console.log('%c防止页面后退', 'color:#da2f33');
                this.isOpenBack = true;
                history.pushState(null, null, document.URL);
                window.addEventListener('popstate', this.backCommon);
            }
        },
        //启用浏览器返回
        enableBack() {
            if (this.isOpenBack) {
                console.log('%c启用浏览器返回', 'color:#da2f33');
                this.isOpenBack = false;
                history.go(-1);
                window.removeEventListener('popstate', this.backCommon);
            }
        },
        backCommon() {
            if (this.$route.path != PAGE_ORDER_INFO) {
                try {
                    if (
                        this.$store.state.common.currentScene >= QZ_CURRENT_SCENE.APP &&
                        this.$store.state.common.currentScene < QZ_CURRENT_SCENE.WX_MIN
                    ) {
                        let returnObj = JSON.parse(
                            this.$store.state.common.config.app.exposeMethodName
                        );
                        if (returnObj.type == 2) {
                            window.location.href = returnObj.url;
                        }
                    }
                } catch (error) {}
            } else {
                if (this.$route.query.backIndex) {
                    if (this.$route.query.backIndexType == 1) {
                        setTimeout(() => {
                            this.isOpenBack = false;
                            window.removeEventListener('popstate', this.backCommon);
                        }, 100);
                        this.$util.pageJump(PAGE_ROOT, {
                            backType: 2,
                        });
                    } else if (this.$route.query.backIndexType == 2) {
                        this.enableBack();
                        setTimeout(() => {
                            window.history.go(-3);
                        }, 100);
                    } else if (this.$route.query.backIndexType == 3) {
                    } else if (this.$route.query.backIndexType == 4) {
                        // wx.miniProgram.navigateTo	参数与小程序接口一致	1.6.4
                        // wx.miniProgram.navigateBack	参数与小程序接口一致	1.6.4
                        // wx.miniProgram.switchTab	参数与小程序接口一致	1.6.5
                        // wx.miniProgram.reLaunch	参数与小程序接口一致	1.6.5
                        // wx.miniProgram.redirectTo	参数与小程序接口一致	1.6.5
                        // wx.miniProgram.reLaunch({
                        //     url: `/pages/index/index`,
                        // });
                        wx.miniProgram.reLaunch({
                            url: `/pages/index/index`,
                        });
                    } else {
                        this.enableBack();
                        setTimeout(() => {
                            window.history.go(-2);
                        }, 100);
                    }
                }
                history.pushState(null, null, document.URL);
            }
        },
        // 退款说明
        showRefundPriceTip() {
            return this.$confirm.open({
                title: '温馨提示',
                message:
                    '当商品使用优惠价格付款时，退款则为订单中该商品的平均价进行退款，其中优惠券兑换商品不参与平均计算。',
                confirmText: '我知道了',
                hideCancelBtn: true,
            });
        },
        toMembershipCode(item) {
            if (item.isExpire) {
                return this.$toast(`该权益账号已过期`);
            }
            let pageData = { type: -1, orderNo: this.orderDetail.orderNo, itemId: item.itemId };
            if (item.structureType == 10) {
                pageData.type = 1;
            } else if (item.structureType == 15) {
                pageData.type = 2;
            }
            this.$util.pageJump(PAGE_WALLET, pageData);
        },
        // 查询快递信息
        toExpire(item) {
            this.$util.pageJump(PAGE_EXPRESS, {
                orderNo: this.orderDetail.orderNo,
                itemId: item.itemId,
            });
        },
    },
};
</script>
<style lang="less">
@import url('~@/lib/base.less');
.amap-logo {
    display: none;
    opacity: 0 !important;
}
.amap-copyright {
    opacity: 0;
}
.refresh-line {
    .base-center-x;
    top: .pxToRem(20) [];
    height: .pxToRem(48) [];
    line-height: .pxToRem(48) [];
    padding: 0 .pxToRem(16) [];
    font-size: .pxToRem(24) [];
    z-index: 10;
    border-radius: .pxToRem(24) [];
    background: rgba(0, 0, 0, 0.7);
    color: white;
}
.delivery-map {
    .delivery-receiver {
        width: .pxToRem(64) [];
        height: .pxToRem(64) [];
        transform: translate(-50%, -100%);
        background-image: url('~@/assets/icons/icon-delivery-receiver.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
    .delivery-store {
        width: .pxToRem(64) [];
        height: .pxToRem(64) [];
        transform: translate(-50%, -100%);
        background-image: url('~@/assets/icons/icon-delivery-store.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
    .delivery-courier {
        width: .pxToRem(136) [];
        height: .pxToRem(148) [];
        transform: translate(-50%, -100%);
        background-image: url('~@/assets/icons/icon-delivery-courier-bg.png');
        background-size: contain;
        background-repeat: no-repeat;
        &::before {
            content: ' ';
            position: absolute;
            top: .pxToRem(25) [];
            left: .pxToRem(29) [];
            width: .pxToRem(84) [];
            height: .pxToRem(90) [];
            background-image: url('~@/assets/icons/icon-delivery-courier.png');
            background-size: .pxToRem(84) [] .pxToRem(90) [];
            background-repeat: no-repeat;
        }
    }
    #delivery-map {
        width: 100%;
        height: 100%;
    }
}
.delivery-content {
    padding: .pxToRem(30) [] 0;
    .delivery-title {
        width: 100%;
        text-align: center;
        line-height: .pxToRem(56) [];
        height: .pxToRem(56) [];
        font-size: .pxToRem(34) [];
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
    }
    .delivery-text {
        width: 100%;
        text-align: center;
        line-height: .pxToRem(56) [];
        height: .pxToRem(56) [];
        font-size: .pxToRem(34) [];
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        .bold {
            color: @theme_color_first;
        }
    }
    .delivery-text + .delivery-title {
        margin-top: .pxToRem(-12) [];
    }
}

.rider-info {
    display: flex;
    align-items: center;
    width: .pxToRem(680) [];
    height: .pxToRem(100) [];
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.05);
    border-radius: .pxToRem(12) [];
    .rider-info-icon {
        margin-left: .pxToRem(20) [];
        width: .pxToRem(64) [];
        height: .pxToRem(64) [];
        background-image: url('~@/assets/icons/icon-delivery-courier.png');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .rider-info-name {
        flex: 1;
        text-align: right;
        margin-right: .pxToRem(20) [];
        font-size: .pxToRem(24) [];
    }
    .rider-info-btn {
        margin-right: .pxToRem(20) [];
        width: .pxToRem(120) [];
        text-align: center;
        height: .pxToRem(48) [];
        line-height: .pxToRem(48) [];
        border: .pxToRem(1) [] solid rgba(0, 0, 0, 0.9);
        border-radius: .pxToRem(8) [];
    }
    .rider-info-a {
        height: .pxToRem(48) [];
        text-decoration: none;
        color: inherit;
    }
}
</style>

<style lang="less" scoped>
@import url('~@/lib/base.less');
.order-info {
    .order-header {
        display: inline-block;
        background: white;
        margin-top: 0 !important;
        border-radius: 0 0 .pxToRem(40) [] .pxToRem(40) [];
        .red {
            color: #c7403d;
        }
        .bold {
            font-weight: bold;
        }
        .status-icon {
            width: .pxToRem(180) [];
            height: .pxToRem(180) [];
            margin: 0 auto;
            margin-top: .pxToRem(30) [];
            background: #f2f2f2;
            border: .pxToRem(1) [] solid #d9d9d9;
            border-radius: .pxToRem(90) [];
            box-sizing: border-box;
            &::before {
                content: ' ';
                .base-center;
                .sq(64);
            }
        }
        .status-text {
            width: 100%;
            line-height: .pxToRem(56) [];
            margin-top: .pxToRem(20) [];
            text-align: center;
            font-size: .pxToRem(34) [];
            color: rgba(0, 0, 0, 0.9);
        }
        .status-tip {
            width: 100%;
            line-height: .pxToRem(36) [];
            text-align: center;
            font-size: .pxToRem(24) [];
            color: rgba(0, 0, 0, 0.5);
            margin-top: .pxToRem(10) [];
            padding: 0 .pxToRem(50) [];
            box-sizing: border-box;
        }
        > .btn {
            width: .pxToRem(150) [];
            height: .pxToRem(60) [];
            line-height: .pxToRem(60) [];
            margin: .pxToRem(30) [] auto .pxToRem(54) [];
            background: @theme_color_first;
            text-align: center;
            border-radius: .pxToRem(30) [];
            font-size: .pxToRem(24) [];
            color: white;
        }
        .btn-list {
            display: flex;
            justify-content: center;
            height: .pxToRem(60) [];
            line-height: .pxToRem(60) [];
            margin: .pxToRem(30) [] auto .pxToRem(54) [];
            text-align: center;
            .btn {
                width: .pxToRem(150) [];
                height: .pxToRem(60) [];
                line-height: .pxToRem(60) [];
                background: @theme_color_first;
                text-align: center;
                border-radius: .pxToRem(30) [];
                font-size: .pxToRem(24) [];
                color: white;
                & + .btn {
                    margin-left: .pxToRem(20) [];
                }
            }
        }
        .header-tip {
            color: rgba(0, 0, 0, 0.5);
            text-align: center;
            font-size: .pxToRem(24) [];
            margin-top: .pxToRem(18) [];
            height: .pxToRem(36) [];
            line-height: .pxToRem(36) [];
        }
        .ticket {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: .pxToRem(200) [];
            min-height: .pxToRem(200) [];
            background: @theme_color_first;
            margin: 0 auto;
            margin-top: .pxToRem(20) [];
            border-radius: 100%;
            .text {
                color: white;
                font-size: .pxToRem(44) [];
                text-align: center;
                line-height: .pxToRem(52) [];
                &:first-child {
                    margin-top: .pxToRem(20) [];
                }
                &:last-child {
                    margin-bottom: .pxToRem(20) [];
                }
            }
            &.n-4 {
                width: .pxToRem(250) [];
            }
            &.n-5 {
                width: .pxToRem(300) [];
            }
        }
        width: 100%;
        margin-top: .pxToRem(20) [];
        .ticket-list {
            width: 100%;
            margin-top: .pxToRem(20) [];
            .ticket-item {
                display: inline-block;
                width: .pxToRem(320) [];
                height: .pxToRem(110) [];
                box-sizing: border-box;
                border: .pxToRem(1) [] solid #e5e5e5;
                border-radius: .pxToRem(20) [];
                .ticket-box,
                .mobile-box {
                    display: inline-block;
                    width: 50%;
                    height: .pxToRem(110) [];
                    .title,
                    .value {
                        .base-center-x;
                        white-space: nowrap;
                    }
                    .title {
                        bottom: .pxToRem(16) [];
                        height: .pxToRem(36) [];
                        line-height: .pxToRem(36) [];
                        color: rgba(0, 0, 0, 0.5);
                    }
                    .value {
                        top: .pxToRem(16) [];
                    }
                }
                .ticket-box {
                    .value {
                        color: #da2f33;
                        font-weight: bold;
                        font-size: .pxToRem(32) [];
                    }
                }
                &:nth-child(odd) {
                    margin-left: .pxToRem(50) [];
                    margin-right: .pxToRem(5) [];
                }
                &:nth-child(even) {
                    margin-left: .pxToRem(5) [];
                    margin-right: .pxToRem(50) [];
                }
            }
        }
    }

    .type-20-tip {
        position: relative;
        height: .pxToRem(30) [];
        line-height: .pxToRem(30) [];
        font-size: .pxToRem(24) [];
        color: rgba(102, 102, 102, 1);
        background: rgba(245, 245, 245, 1);
        text-indent: .pxToRem(67) [];
        padding: .pxToRem(20) [] 0;
        img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: .pxToRem(24) [];
            width: .pxToRem(33) [];
            height: .pxToRem(28) [];
        }
    }
    .model {
        margin: 0 .pxToRem(20) [];
        margin-top: .pxToRem(10) [];
        background: white;
        border-radius: .pxToRem(30) [];
        overflow: hidden;
    }
    .product-list {
        padding: .pxToRem(25) [] 0;
    }
    .line {
        line-height: .pxToRem(78) [];
        .jc-sb;
        .line-item {
        }
        .title {
            justify-content: center;
            width: .pxToRem(150) [];
            padding-left: .pxToRem(24) [];
            font-size: .pxToRem(28) [];
            color: rgba(0, 0, 0, 0.9);
        }
        .value {
            flex: 1;
            padding-right: .pxToRem(24) [];
            font-size: .pxToRem(26) [];
            color: rgba(0, 0, 0, 0.5);
            text-align: right;
        }
        .refund-img {
            position: absolute;
            top: .pxToRem(20) [];
            left: .pxToRem(130) [];
            width: .pxToRem(100) [];
            height: .pxToRem(100) [];
            z-index: 10;
        }

        &.store-info {
            .title {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .value {
                .name {
                    line-height: .pxToRem(40) [];
                    margin: .pxToRem(29) [] 0;
                }
            }
        }
        &.line-more {
            color: #847d7d;
            padding: 0 .pxToRem(24) [];
            line-height: .pxToRem(32) [];
            font-size: .pxToRem(20) [];
            margin-bottom: .pxToRem(44) [];
        }
        &.total {
            height: auto;
            padding: .pxToRem(20) [] 0;
            .value {
                color: rgba(0, 0, 0, 0.9);
                font-size: .pxToRem(26) [];
                font-weight: 600;
                .price {
                    color: @theme_color_first;
                    margin-left: .pxToRem(14) [];
                    font-size: .pxToRem(36) [];
                    padding-left: .pxToRem(24) [];
                    &::before {
                        content: '¥';
                        position: absolute;
                        left: 0;
                        bottom: .pxToRem(5) [];
                        height: .pxToRem(36) [];
                        line-height: .pxToRem(36) [];
                        width: .pxToRem(24) [];
                        text-align: center;
                        font-weight: 400;
                        font-size: .pxToRem(24) [];
                    }
                }
                .refund-price {
                    line-height: .pxToRem(48) [];
                    font-size: .pxToRem(36) [];
                    color: #fa8c16;
                    margin-left: .pxToRem(30) [];
                    &::before {
                        content: '退款金额￥';
                        font-size: .pxToRem(26) [];
                    }
                }
                .refund-price-tip {
                    display: inline-block;
                    font-size: .pxToRem(24) [];
                    width: .pxToRem(32) [];
                    text-align: center;
                    height: .pxToRem(32) [];
                    line-height: .pxToRem(32) [];
                    background: rgba(0, 0, 0, 0.9);
                    border-radius: 100%;
                    color: white;
                    margin-left: .pxToRem(12) [];
                    margin-top: .pxToRem(-8) [];
                }
                p {
                    display: flex;
                    justify-content: flex-end;
                    line-height: .pxToRem(48) [];
                    align-items: center;
                }
                .integral {
                    color: @theme_color_first;
                    font-size: .pxToRem(36) [];
                }
            }
        }
        &.detail {
            height: .pxToRem(100) [];
            .refund-img {
                display: none;
                .base-center-y;
                left: .pxToRem(160) [];
                .sq(80);
            }
            .img {
                .base-center-y;
                left: .pxToRem(30) [];
                max-width: .pxToRem(110) [];
                max-height: .pxToRem(80) [];
            }
            .name {
                .base-center-y;
                left: .pxToRem(160) [];
                right: .pxToRem(265) [];
                line-height: .pxToRem(40) [];
                .overflow(2);
                font-size: .pxToRem(28) [];
                color: rgba(0, 0, 0, 0.9);
                &.favour {
                    .p {
                        text-indent: .pxToRem(50) [];
                    }
                    &:before {
                        content: '惠';
                        text-indent: .pxToRem(0) [];
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: #d90209;
                        height: .pxToRem(40) [];
                        line-height: .pxToRem(40) [];
                        width: .pxToRem(40) [];
                        text-align: center;
                        border-radius: .pxToRem(4) [];
                        color: white;
                    }
                }
                &.voucher {
                    .p {
                        text-indent: .pxToRem(50) [];
                    }
                    &:before {
                        content: '代';
                        text-indent: .pxToRem(0) [];
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: #d90209;
                        height: .pxToRem(40) [];
                        line-height: .pxToRem(40) [];
                        width: .pxToRem(40) [];
                        text-align: center;
                        border-radius: .pxToRem(4) [];
                        color: white;
                    }
                }
            }
            .sku-box {
                float: left;
                margin-top: .pxToRem(52) [];
                margin-bottom: .pxToRem(12) [];
                margin-left: .pxToRem(160) [];
                height: .pxToRem(36) [];
                line-height: .pxToRem(36) [];
                width: .pxToRem(360) [];
                overflow: hidden;
                background: rgba(0, 0, 0, 0.1);
                .sku-item {
                    box-sizing: border-box;
                    padding: 0 .pxToRem(10) [];
                    font-size: .pxToRem(22) [];
                    .overflow(1);
                }
                &.open {
                    height: auto;
                    overflow: unset;
                }
            }
            .num {
                .base-center-y;
                right: .pxToRem(140) [];
                line-height: .pxToRem(40) [];
                color: rgba(0, 0, 0, 0.5);
                font-size: .pxToRem(26) [];
            }
            .price {
                position: absolute;
                right: .pxToRem(30) [];
                top: .pxToRem(5) [];
                line-height: .pxToRem(40) [];
                color: rgba(0, 0, 0, 0.9);
                font-weight: 600;
                padding-left: .pxToRem(24) [];
                &::before {
                    content: '¥';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: .pxToRem(36) [];
                    line-height: .pxToRem(36) [];
                    width: .pxToRem(24) [];
                    text-align: center;
                    font-weight: 400;
                    font-size: .pxToRem(24) [];
                }
                &.out {
                    .base-center-y;
                }
            }
            .origin-price {
                position: absolute;
                right: .pxToRem(30) [];
                bottom: .pxToRem(12) [];
                height: .pxToRem(36) [];
                font-size: .pxToRem(22) [];
                color: rgba(0, 0, 0, 0.3);
                line-height: .pxToRem(36) [];
                text-decoration: line-through;
            }
            &.has-sku {
                height: auto;
                .name {
                    top: .pxToRem(30) [];
                    .overflow(1);
                }
            }
            &.canceled {
                .refund-img {
                    display: unset;
                }
                .name {
                    color: rgba(0, 0, 0, 0.3);
                }
            }
            &.hide-origin-price {
                .price {
                    top: 50%;
                    transform: translateY(-50%);
                }
                .origin-price {
                    display: none;
                }
            }
        }
        &.line-title {
            height: .pxToRem(88) [];
            line-height: .pxToRem(88) [];
            font-size: .pxToRem(30) [];
            font-weight: 600;
            color: rgba(0, 0, 0, 0.9);
        }
        &.address-info-box {
            height: auto;
            .title {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .value {
                padding: .pxToRem(16) [] .pxToRem(24) [] .pxToRem(16) [] 0;
                .address {
                    .base-center-y;
                    right: .pxToRem(30) [];
                    line-height: .pxToRem(40) [];
                    .overflow(2);
                }
            }
        }
        &.btn-line {
            height: .pxToRem(60) [];
            line-height: .pxToRem(60) [];
            margin-top: .pxToRem(15) [];
            margin-bottom: .pxToRem(40) [];
            .value {
                .btn {
                    display: inline-block;
                    box-sizing: border-box;
                    height: .pxToRem(58) [];
                    line-height: .pxToRem(58) [];
                    background: white;
                    border: .pxToRem(1) [] solid #313131;
                    border-radius: .pxToRem(30) [];
                    font-size: .pxToRem(26) [];
                    color: rgba(0, 0, 0, 0.9);
                    padding: 0 .pxToRem(30) [];
                    margin-left: .pxToRem(15) [];
                    &.contact {
                        padding-left: .pxToRem(72) [];
                        &:after {
                            content: ' ';
                            .base-center-y;
                            left: .pxToRem(30) [];
                            .sq(32);
                            background: url('~@/assets/icons/icon-order-customer-service.png');
                            background-size: cover;
                        }
                    }
                }
            }
        }
        &.border-top {
            border-top: .pxToRem(1) [] solid rgba(0, 0, 0, 0.15);
        }
        &.border-bottom {
            border-bottom: .pxToRem(1) [] solid rgba(0, 0, 0, 0.15);
        }
        &.border-bottom {
            border-bottom: .pxToRem(2) [] solid #ebebeb;
        }
        &.order-information {
            line-height: .pxToRem(78) [];
        }
        &.order-copy {
            .value {
                span {
                    margin-left: .pxToRem(30) [];
                    color: rgba(0, 0, 0, 0.9);
                }
            }
        }
    }
    .tip {
        background: #f0f0f0;
        padding: .pxToRem(50) [];
        padding-top: .pxToRem(30) [];
        color: #999999;
        font-size: .pxToRem(24) [];
        .title {
            height: .pxToRem(40) [];
            line-height: .pxToRem(40) [];
            color: rgba(0, 0, 0, 0.9);
            padding-left: .pxToRem(48) [];
            margin-bottom: .pxToRem(10) [];
            &::after {
                content: ' ';
                .base-center-y;
                left: 0;
                width: .pxToRem(30) [];
                height: .pxToRem(26) [];
                background: url('~@/assets/icons/icon-order-info-horn.png');
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
        .p {
            line-height: .pxToRem(26) [];
            line-height: .pxToRem(40) [];
            color: rgba(0, 0, 0, 0.5);
        }
    }
    &.order--5 {
        .order-header {
            .status-icon {
                &::before {
                    background: url(~@/assets/icons/icon-order-status--5.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            .status-tip {
                margin-bottom: .pxToRem(68) [];
            }
        }
    }
    &.order-0 {
        .order-header {
            .down-time {
                width: .pxToRem(180) [];
                height: .pxToRem(180) [];
                margin: 0 auto;
                margin-top: .pxToRem(30) [];
                display: flex;
                flex-direction: column;
                justify-content: center;
                box-sizing: border-box;
                border: .pxToRem(1) [] solid @theme_color_first;
                border-radius: 100%;
                background: #fdf0f0;
                color: @theme_color_first;
                text-align: center;
                font-size: .pxToRem(40) [];
                font-weight: 600;
            }
            .status-text {
                color: @theme_color_first;
            }
        }
    }
    &:not(.takeout) {
        &.order-5,
        &.order-15 {
            .order-header {
                // background-color: unset;
                overflow: hidden;
                border-radius: unset;
                background-image: linear-gradient(#d90209, 60%, rgba(0, 0, 0, 0));
                background-size: 100% .pxToRem(400) [];
                background-repeat: no-repeat;
                .title {
                    display: inline-block;
                    height: .pxToRem(100) [];
                    line-height: .pxToRem(100) [];
                    text-indent: .pxToRem(50) [];
                    font-size: .pxToRem(34) [];
                    padding-right: .pxToRem(48) [];
                    color: white;
                    background: url('~@/assets/icons/icon-right-white.png');
                    background-repeat: no-repeat;
                    background-position: center right;
                    background-size: .pxToRem(32) [] .pxToRem(32) [];
                }
                .product-list {
                    width: .pxToRem(710) [];
                    border-radius: .pxToRem(40) [];
                    // border-top-left-radius: .pxToRem(40) [];
                    // border-top-right-radius: .pxToRem(40) [];
                    padding-top: 0;
                    padding-bottom: 0;
                    background: white;
                    margin: 0 auto;
                    margin-top: .pxToRem(10) [];
                    .product-item {
                        border-bottom: .pxToRem(1) [] solid rgba(0, 0, 0, 0.15);
                        min-height: .pxToRem(174) [];
                        .product-image {
                            position: absolute;
                            top: .pxToRem(24) [];
                            left: .pxToRem(10) [];
                            width: .pxToRem(165) [];
                            height: .pxToRem(120) [];
                        }
                        .product-name {
                            position: absolute;
                            top: .pxToRem(24) [];
                            left: .pxToRem(205) [];
                            right: .pxToRem(230) [];
                            line-height: .pxToRem(40) [];
                            font-size: .pxToRem(28) [];
                            color: rgba(0, 0, 0, 0.9);
                            .overflow(2);
                        }
                        .quantity {
                            position: absolute;
                            top: .pxToRem(110) [];
                            left: .pxToRem(205) [];
                            line-height: .pxToRem(40) [];
                            font-size: .pxToRem(26) [];
                            color: rgba(0, 0, 0, 0.5);
                        }
                        .wait-text {
                            .base-center-y;
                            right: .pxToRem(30) [];
                            display: flex;
                            line-height: .pxToRem(40) [];
                            font-size: .pxToRem(30) [];
                            font-weight: bold;
                            .wait-spot {
                                font-size: .pxToRem(30) [];
                                transition: 0s;
                                span {
                                    display: inline-block;
                                    overflow: hidden;
                                    animation: loadingMove 2.4s infinite;
                                }
                            }
                        }
                        @keyframes loadingMove {
                            0% {
                                width: 33%;
                            }
                            33% {
                                width: 33%;
                            }
                            34% {
                                width: 66%;
                            }
                            66% {
                                width: 66%;
                            }
                            67% {
                                width: 100%;
                            }
                            100% {
                                width: 100%;
                            }
                        }
                        /*Safari 和 Chrome:*/
                        @-webkit-keyframes loadingMove {
                            0% {
                                width: 33%;
                            }
                            33% {
                                width: 33%;
                            }
                            34% {
                                width: 66%;
                            }
                            66% {
                                width: 66%;
                            }
                            67% {
                                width: 100%;
                            }
                            100% {
                                width: 100%;
                            }
                        }
                        .subheading {
                            position: absolute;
                            top: .pxToRem(24) [];
                            right: .pxToRem(30) [];
                            line-height: .pxToRem(28) [];
                            font-size: .pxToRem(20) [];
                            color: #d90209;
                        }
                        .ticket-desc {
                            padding-right: .pxToRem(30) [];
                            padding-bottom: .pxToRem(70) [];
                            padding-top: .pxToRem(54) [];
                            line-height: .pxToRem(56) [];
                            text-align: right;
                            font-size: .pxToRem(48) [];
                            color: #d90209;
                            font-weight: bold;
                            .btn-look {
                                height: .pxToRem(60) [];
                                line-height: .pxToRem(60) [];
                                font-size: .pxToRem(26) [];
                                padding: 0 .pxToRem(20) [];
                                display: inline-block;
                                background: #d90209;
                                color: white;
                                border-radius: .pxToRem(10) [];
                                &.expire {
                                    background: rgba(0, 0, 0, 0.3);
                                }
                            }
                        }
                        .place-order-mobile {
                            position: absolute;
                            bottom: .pxToRem(24) [];
                            right: .pxToRem(30) [];
                            line-height: .pxToRem(36) [];
                            font-size: .pxToRem(24) [];
                            color: rgba(0, 0, 0, 0.5);
                        }
                        &.cancel {
                            .image-cancel {
                                .base-center-y;
                                left: .pxToRem(54) [];
                                .sq(80);
                                z-index: 2;
                            }
                            .subheading {
                                color: rgba(0, 0, 0, 0.5);
                            }

                            .cancel-reason {
                                .base-center-y;
                                right: .pxToRem(30) [];
                                line-height: .pxToRem(40) [];
                                font-size: .pxToRem(30) [];
                                font-weight: bold;
                                margin-right: .pxToRem(30) [];
                                color: rgba(136, 136, 136, 0.9);
                            }

                            &::before {
                                content: ' ';
                                position: absolute;
                                top: .pxToRem(24) [];
                                left: .pxToRem(10) [];
                                width: .pxToRem(165) [];
                                height: .pxToRem(120) [];
                                background: rgba(255, 255, 255, 0.6);
                                z-index: 1;
                            }
                        }
                    }
                    .product-combo-box {
                        padding-left: .pxToRem(70) [];
                        .product-combo-title {
                            font-size: .pxToRem(32) [];
                            margin-left: .pxToRem(30 - 70) [];
                            height: .pxToRem(64) [];
                            line-height: .pxToRem(64) [];
                        }
                        .product-item {
                            &:before {
                                content: ' ';
                                .base-center-y;
                                left: .pxToRem(-24) [];
                                width: .pxToRem(30) [];
                                height: 0;
                                border-top: .pxToRem(1) [] dashed @theme_color_first;
                            }
                            &::after {
                                content: ' ';
                                .base-center-y;
                                left: .pxToRem(-40) [];
                                width: .pxToRem(10) [];
                                height: .pxToRem(10) [];
                                border-radius: 100%;
                                border: .pxToRem(1) [] dashed @theme_color_first;
                                background: white;
                            }
                        }
                        &::before {
                            content: ' ';
                            position: absolute;
                            left: .pxToRem(36) [];
                            top: .pxToRem(64) [];
                            bottom: .pxToRem(90) [];
                            width: .pxToRem(0) [];
                            border-left: .pxToRem(1) [] dashed @theme_color_first;
                        }
                    }
                    &.hide-radius {
                        border-bottom-left-radius: .pxToRem(0) [];
                        border-bottom-right-radius: .pxToRem(0) [];
                    }
                }
                .line-up {
                    margin: 0 auto;
                    width: .pxToRem(710) [];
                    line-height: .pxToRem(80) [];
                    text-align: center;
                    background: white;
                    border-bottom-left-radius: .pxToRem(40) [];
                    border-bottom-right-radius: .pxToRem(40) [];
                }
                // .status-icon {
                //     background: #e1f9ec;
                //     border: .pxToRem(1) [] solid #a6e1c1;
                //     &::before {
                //         background: url(~@/assets/icons/icon-order-status-5.png);
                //         background-size: contain;
                //         background-repeat: no-repeat;
                //         background-position: center;
                //     }
                // }
                // .status-tip {
                //     margin-bottom: .pxToRem(68) [];
                // }
            }
            &.delivery {
                &.order-15:not(.complete-delivery) {
                    .order-header {
                        .status-icon {
                            &::before {
                                background: url(~@/assets/icons/icon-order-status-10.png);
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center;
                            }
                        }
                        .status-text {
                            color: rgba(0, 0, 0, 0.9);
                        }
                        &::before {
                            content: '';
                            background: none;
                        }
                    }
                    .map {
                        .delivery-map {
                            width: 100%;
                            height: .pxToRem(440) [];
                        }
                    }
                }
            }
            &:not(.delivery-0):not(.delivery-20):not(.delivery-30):not(.delivery-99) {
                &::before {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: .pxToRem(400) [];
                    // background: #d90209;
                    // background-image: linear-gradient(#d90209, 60%, rgba(0, 0, 0, 0));
                    // background: white;
                }
            }
            &.delivery--5,
            &.delivery-0,
            &.delivery-20,
            &.delivery-30,
            &.delivery-99 {
                .order-header {
                    border-bottom-left-radius: .pxToRem(40) [];
                    border-bottom-right-radius: .pxToRem(40) [];
                }
            }

            &.delivery-0,
            &.delivery-99 {
                .order-header {
                    background: white;
                }
            }
            &::before {
                content: ' ';
                background-image: linear-gradient(#d90209, 60%, rgba(0, 0, 0, 0));
                background-size: 100% .pxToRem(400) [];
                background-repeat: no-repeat;
            }
        }
    }
    &.order-5 {
        .order-header {
            .status-icon {
                background: #e1f9ec;
                border: .pxToRem(1) [] solid #a6e1c1;
                &::before {
                    background: url(~@/assets/icons/icon-order-status-5.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            .status-tip {
                margin-bottom: .pxToRem(68) [];
            }
        }
    }
    &.order-10 {
        .order-header {
            .status-icon {
                background: #e1f9ec;
                border: .pxToRem(1) [] solid #a6e1c1;
                &::before {
                    background: url(~@/assets/icons/icon-order-status-5.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            .status-tip {
                margin-bottom: .pxToRem(68) [];
            }
        }
    }
    &.order-15 {
        .order-header {
            .product-list {
                .product-item {
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
            .status-icon {
                background: #e1f9ec !important;
                border: .pxToRem(1) [] solid #a6e1c1 !important;
                &::before {
                    background: url(~@/assets/icons/icon-order-status-15.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            .status-text {
                color: @theme_color_first;
            }
            .status-tip {
                margin-bottom: .pxToRem(20) [];
            }
        }
    }
}
</style>
<style lang="less" scoped>
@import url('~@/lib/base.less');
.show-original-price {
    .order-info {
        .product-list .detail {
            .name {
                right: .pxToRem(280) [];
            }
            .num {
                left: .pxToRem(464) [];
            }
            .price {
                &:after {
                    content: '到手价';
                    position: absolute;
                    top: 0;
                    left: .pxToRem(-6) [];
                    white-space: nowrap;
                    transform: translateX(-100%);
                    font-size: .pxToRem(22) [];
                }
            }
        }
    }
}
</style>
