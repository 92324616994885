<style lang="less" scoped>
    @import url("~@/lib/base.less");
    .custimer-service-btn {
      .img {
        width: .pxToRem(124) [];
        height: .pxToRem(124) [];
        pointer-events: all;
      }
    }
</style>

<template>
    <div class="components custimer-service-btn">
        <img class="img"
             v-if="type == 0"
             @click="toCs"
             :src="require('@/assets/img/mg_kf.png')">
        <img class="img"
             v-else-if="type == 1"
             @click="toCs"
             :src="require('@/assets/img/mg-kf-index.png')">
    </div>
</template>
<script>
    import { PAGE_CUSTOMER_SERVICE } from 'page';
    export default {
        props: {
            type: {
                type: Number,
                default: 0
            }
        },
        methods: {
            toCs() {
                if (this.$store.state.user.token) {
                    this.$util.pageJump(PAGE_CUSTOMER_SERVICE)
                    // this.$customerService.open();
                }
                else {
                    this.$loginModel.open();
                }
            }
        }
    }
</script>
