import { render, staticRenderFns } from "./btn.vue?vue&type=template&id=06f5f31e&scoped=true"
import script from "./btn.vue?vue&type=script&lang=js"
export * from "./btn.vue?vue&type=script&lang=js"
import style0 from "./btn.vue?vue&type=style&index=0&id=06f5f31e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f5f31e",
  null
  
)

export default component.exports